import { latLng, icon } from 'leaflet';
import debounce from 'lodash/debounce';

const DEFAULT_ZOOM = 11
const DEFAULT_MAP_ZOOM = 5
const DEFAULT_ADDRESS_ZOOM = 16
const DEFAULT_SHOP_ZOOM = 18
const DEFAULT_CENTER = { lat: 50.450001, lng: 30.523333 }

export default {
  name: 'ShippingMapMixin',
  props: {
    config: {
      type: Object,
      default: () => ({})
    },
    draft: {
      type: Object,
      default: () => null
    },
    npCenter: {
      type: Array,
      required: true
    },
    pickupGroups: {
      type: Array,
      required: true
    },
    npCity: {
      type: [Object, null],
      default: () => null
    },
    npShopList: {
      type: Array,
      required: true
    },
    loadingDelivery: {
      type: Boolean,
      required: true
    },
    loadingNewPost: {
      type: Boolean,
      required: true
    },
    loadingUserLocate: {
      type: Boolean,
      required: true
    },
    locateError: {
      type: Boolean,
      required: true
    },
    initialized: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return {
      map: null,
      zoom: DEFAULT_MAP_ZOOM,
      center: latLng(DEFAULT_CENTER.lat, DEFAULT_CENTER.lng),
      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      attribution:
        '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      mapOptions: {
        scrollWheelZoom: 'center',
        doubleClickZoom: 'center',
        touchZoom: 'center',
        minZoom: 2,
        maxZoom: 18,
        boxZoom: false,
        zoomControl: false,
        attributionControl: false,
        zoomSnap: 0.5
      },
      icon: {
        npIcon: icon({
          iconUrl: '/assets/map/pointer-new-np.svg',
          iconSize: [40, 48.2],
          iconAnchor: [20, 55]
        }),
        npActiveIcon: icon({
          iconUrl: '/assets/map/pointer-new-np-active.svg',
          iconSize: [40, 49.2],
          iconAnchor: [20, 55]
        }),
        shopIcon: icon({
          iconUrl: '/assets/map/pointer-shop.svg',
          iconSize: [52, 57],
          iconAnchor: [26, 28]
        }),
        shopActiveIcon: icon({
          iconUrl: '/assets/map/pointer-shop-active.svg',
          iconSize: [52, 57],
          iconAnchor: [26, 57]
        })
      },
      methodZoom: {},
      skipNextRequest: false,
      activeDelivery: false,
      activeDeliveryPopup: false,
      keepCenter: null,
      buffer: null
    }
  },
  watch: {
    'draft.method': {
      handler: function (val, oldVal) {
        if (val === 'NOVA') {
          this.resetToMap()

          return
        }

        if (val === oldVal || !this.draft?.city?.coordinates) return

        this.skipNextRequest = true
        this.activeDelivery = false

        const zoom = val === this.config.codes.delivery
          ? DEFAULT_ADDRESS_ZOOM
          : DEFAULT_ZOOM

        this.setCenterAndZoom(
          this.draft?.city.coordinates.lat,
          this.draft?.city.coordinates.lng,
          this.methodZoom[this.draft?.method] || zoom
        )
      }
    },
    'draft.city': {
      immediate: true,
      handler: function (val, oldVal) {
        if (!val?.id || val?.id === oldVal?.id) return

        this.activeDelivery = false
        this.skipNextRequest = true

        const zoom = this.draft?.method === this.config.codes.delivery
          ? DEFAULT_ADDRESS_ZOOM
          : DEFAULT_ZOOM

        this.setCenterAndZoom(
          val.coordinates.lat,
          val.coordinates.lng,
          zoom
        )
      }
    },
    'draft.type': {
      handler: function (val, oldVal) {
        if (!val || val === oldVal || !this.draft?.city?.coordinates) return
        if (this.draft?.method === this.config.codes.delivery) return

        this.setCenterAndZoom(
          this.draft?.city.coordinates.lat,
          this.draft?.city.coordinates.lng,
          DEFAULT_ZOOM
        )
      }
    },
    'draft.address': {
      handler: function (val, oldVal) {
        this.activeDelivery = !!val

        if (val?.label === oldVal?.label || !val?.coordinates) return

        this.skipNextRequest = true

        this.setCenterAndZoom(
          val.coordinates[1],
          val.coordinates[0],
          null
        )
      }
    },
    'draft.shop': {
      immediate: true,
      handler: function (val, oldVal) {
        if (!val?.id || val?.id === oldVal?.id) return
        if (this.draft?.method === this.config.codes.delivery) return

        this.setCenterAndZoom(
          val.lat,
          val.long,
          DEFAULT_SHOP_ZOOM
        )

        this.$nextTick(() => {
          this.openShopPopUp(this.draft?.type, val.id)
        })
      }
    },
    initialized (val, oldVal) {
      if (!val || !this.buffer || val === oldVal) return

      this.setCenterAndZoom(this.buffer.lat, this.buffer.lng, this.buffer.zoom)

      this.buffer = null
    },
    npCenter (val) {
      if (!val?.length) return

      const [lng, lat] = val

      const zoom = this.draft?.npType === 'address' ? DEFAULT_ADDRESS_ZOOM : DEFAULT_ZOOM

      this.setCenterAndZoom(lat, lng, zoom)
    },
    'draft.npShop': {
      handler: function (val) {
        if (!val) return

        this.setCenterAndZoom(
          val.latitude,
          val.longitude,
          DEFAULT_SHOP_ZOOM
        )

        this.$nextTick(() => {
          this.openShopPopUp('nova', val.id)
        })
      }
    },
    'draft.npType': {
      handler: function (val, oldVal) {
        if (this.draft?.method !== 'NOVA' || val === oldVal) return

        this.resetToMap()
      }
    }
  },
  beforeDestroy () {
    this.mapEventsStop()
  },
  computed: {
    isNova () {
      return this.draft?.method === this.config.codes.newPost
    },
    isNovaCityReady () {
      return this.isNova && !!this.draft.npCity
    },
    isNovaDelivery () {
      return this.isNovaCityReady && this.draft?.npType === this.config.newPostTypes.address
    }
  },
  methods: {
    resetToMap () {
      this.setCenterAndZoom(
        DEFAULT_CENTER.lat,
        DEFAULT_CENTER.lng,
        DEFAULT_MAP_ZOOM
      )
    },
    zoomIn () {
      return this.map?.zoomIn()
    },
    zoomOut () {
      return this.map?.zoomOut()
    },
    setDeliveryCoordinatesHandler: debounce(function () {
      if (this.skipNextRequest) {
        this.skipNextRequest = false
        return
      }

      this.activeDelivery = false

      this.$emit('delivery-coordinates', this.center)
    }, 800),
    setCenter (center) {
      if (this.draft?.method !== this.config.codes.delivery && !this.isNovaDelivery) return

      this.center = center

      this.setDeliveryCoordinatesHandler()
    },
    setZoom (zoom) {
      this.zoom = zoom

      this.methodZoom[this.draft?.method] = zoom
    },
    setCenterAndZoom (lat, lng, zoom) {
      if (!this.map?.setView) {
        this.buffer = {
          lat: lat,
          lng: lng,
          zoom: zoom
        }

        return
      }

      const currentZoom = zoom || this.zoom
      const currentCenter = latLng(+lat, +lng) || this.center

      this.zoom = currentZoom

      if (!this.initialized) {
        this.buffer = {
          lat: lat,
          lng: lng,
          zoom: currentZoom
        }

        return
      }

      this.map.setView(currentCenter, currentZoom, { animation: true })
    },
    popupOpen (e) {
      if (!e?.popup?.options?.shop) return

      const event = this.isNova
        ? 'set-np-shop'
        : 'set-shop'

      this.$emit(event, e.popup.options.shop)
    },
    clearDeliveryError () {
      this.$emit('clear-locate-error')
    },
    move () {
      this.$emit('move')
    },
    moveStart () {
      if (this.draft?.method !== this.config.codes.delivery) return

      this.skipNextRequest = false
      this.activeDelivery = false

      this.move()
    },
    zoomStart () {
      this.keepCenter = this.center
    },
    zoomEnd () {
      this.center = this.keepCenter
      this.keepCenter = null
      this.skipNextRequest = true
    },
    mapEventsInitialize () {
      if (!this.map) return

      this.map.on('move', this.move);
      this.map.on('zoomstart', this.zoomStart);
      this.map.on('zoomend', this.zoomEnd);
      this.map.on('dragstart', this.moveStart);
      this.map.on('popupopen', this.popupOpen);
    },
    mapEventsStop () {
      if (!this.map) return

      this.map.off('move', this.move);
      this.map.off('zoomstart', this.zoomStart);
      this.map.off('zoomend', this.zoomEnd);
      this.map.off('dragstart', this.moveStart);
      this.map.off('popupopen', this.popupOpen);
    },
    openShopPopUp (type, shopId) {
      const popup = this.$refs[`shop-${type}-${shopId}`]?.[0]?.mapObject

      if (!popup) return

      popup.openPopup()
    }
  }
}
