<template>
  <div class="m-info-block" :class="{ 'm-info-block--start': alignStart }">
    <span class="m-info-block__icon" />
    <div class="m-info-block__message">
      <p>
        <span
          v-if="html"
          v-html="$t(message, vars)"
        />
        <template v-else>
          {{ $t(message, vars) }}
        </template>
        <slot class="m-info-block__additional-text" name="additional-text" />
      </p>
    </div>
    <slot class="m-info-block__action" name="action" />
  </div>
</template>
<script>

export default {
  name: 'MInfoBlock',
  props: {
    /**
     * Message that will be displayed in info block.
     */
    message: {
      type: String,
      default: '',
      required: true
    },
    alignStart: {
      type: Boolean,
      default: () => false
    },
    vars: {
      type: Object,
      default: () => ({})
    },
    html: {
      type: Boolean,
      default: () => false
    }
  }
};
</script>
<style lang="scss">
@import '~theme/css/fonts';

.m-info-block {
  width: 100%;
  box-sizing: border-box;
  background-color: var(--info-block-background, var(--light-gray));
  margin: var(--info-block-margin, 0 0 0);
  padding: var(--spacer-10);
  display: flex;
  align-items: center;

  &--start {
    align-items: flex-start;
  }

  &__icon {
    margin: 0 var(--spacer-12) 0 0;

    @include font-icon(var(--icon-info));

    &:before {
      font-size: var(--font-size-13);
      color: var(--black);
    }
  }

  &__message {
    font-family: var(--font-family-secondary);
    font-size: var(--font-size-14);
    font-weight: var(--font-normal);
    line-height: 1.2;

    p {
      margin: 0;
    }
  }

  &__additional-text {
    font-size: var(--font-size-15);
    color: var(--orange);
  }
  &__action {
    display: flex;
    flex: auto;
    justify-content: flex-end;
  }
  &__action-label {
    cursor: pointer;
    font-family: var(--font-family-secondary);
    font-size: var(--font-size-14);
    font-weight: var(--font-normal);
    line-height: 1.2;
    color: var(--orange);
  }
}
</style>
