<template>
  <div class="shp-area-pickup radio-switcher-simple">
    <template v-for="item of pickupGroups">
      <SfRadio
          v-if="pickupGroups.length !== 1"
          :selected="draft.type"
          :disabled="item.disabled"
          :value="item.type"
          name="delivery-method"
          class="radio-switcher-simple__input"
          @input="setTypeEvent"
      >
        <template #label>
          <div class="sf-radio__label radio-switcher-simple__label">
            <div>{{ $t(item.type) }}</div>
          </div>
        </template>
      </SfRadio>

      <component :is="component" class="m-locate-switcher-shop__smooth">
        <MInputAutocomplete
            v-show="draft.type === item.type"
            :highlight="true"
            :selected="selectedShop"
            :data-list="item.list"
            :label="$t(item.type !== config.codes.varusDrive ? 'Shop from the list or on the map' : 'Drive from the list or on the map')"
            :message="$t('Enter the address of the store from the list of results')"
            property="address"
            @set-value="setShopEvent"
        />
      </component>
    </template>
  </div>
</template>

<script>
import { SfRadio } from '@storefront-ui/vue';
import MInputAutocomplete from 'theme/components/molecules/m-input-autocomplete.vue';
import ASmoothReflow from 'theme/components/atoms/a-smooth-reflow.vue';

export default {
  name: 'ShippingAreaPickup',
  components: { MInputAutocomplete, SfRadio },
  props: {
    draft: {
      type: Object,
      default: () => null
    },
    config: {
      type: Object,
      default: () => null
    },
    pickupGroups: {
      value: Array,
      required: true
    },
    initialized: {
      value: Boolean,
      default: () => false
    }
  },
  computed: {
    selectedShop () {
      return this.draft?.shop || {}
    }
  },
  data () {
    return {
      component: 'div'
    }
  },
  mounted () {
    this.initData()
  },
  methods: {
    initData () {
      if (!this.initialized) return

      const type = this.pickupGroups.find(i => !i.disabled)
      this.setTypeEvent(type?.type)

      if (this.pickupGroups.length !== 1) {
        this.component = ASmoothReflow
      }
    },
    setTypeEvent (type) {
      this.$emit('set-type', type)
      this.setShopEvent(null)
    },
    setShopEvent (shop) {
      this.$emit('set-shop', shop)
    }
  }
}
</script>

<style scoped lang="scss">
@import "~@storefront-ui/shared/styles/helpers/breakpoints";
@import "~theme/css/mixins";
@import "~theme/css/px2rem";

.shp-area-pickup {
}
</style>
