<template>
  <div class="shp-save">
    <ASmoothReflow
      class="shp-save__button-top"
    >
      <MInfoBlock
        v-if="errorMessage.length"
        class="shp-save__notification"
        :message="errorMessage"
      />
    </ASmoothReflow>

    <SfButton
      data-transaction-name="Shipping - Save"
      class="sf-button--primary sf-button--full-width"
      :disabled="!isValid || errorMessage.length || isLoading || isNewPostOverweight"
      @click="sendEvent"
    >
      {{ $t("Save shipping") }}
    </SfButton>

    <ShippingLoading
      v-show="isLoading"
      :absolute="true"
      background="rgba(255, 255, 255, 0.5)"
    />
  </div>
</template>

<script>
import { SfButton } from '@storefront-ui/vue'
import MInfoBlock from 'theme/components/molecules/m-info-block.vue';
import ASmoothReflow from 'theme/components/atoms/a-smooth-reflow.vue';
import ShippingLoading from '$modules/shipping/components/shipping-loading.vue';

export default {
  name: 'ShippingSave',
  components: {
    ShippingLoading,
    ASmoothReflow,
    MInfoBlock,
    SfButton
  },
  props: {
    isValid: {
      type: Boolean,
      default: () => false
    },
    isLoading: {
      type: Boolean,
      default: () => false
    },
    error: {
      type: Object,
      default: () => ({})
    },
    isNewPostOverweight: {
      type: Boolean,
      default: () => false
    }
  },
  computed: {
    errorMessage () {
      if (this.error.shopNotFound ||
        this.error.deliveryNotFound ||
        this.error.deliveryPointNotFound) return 'shipping validation error'

      return false
    }
  },
  methods: {
    sendEvent () {
      this.$emit('save')
    }
  }
}
</script>

<style scoped lang="scss">
@import "~@storefront-ui/shared/styles/helpers/breakpoints";
@import "~theme/css/mixins";
@import "~theme/css/px2rem";

.shp-save {
  padding: 10px;
  position: relative;

  @include for-desktop {
    padding: 0;
    margin-top: auto;
  }

  &__button-top {

  }

  &__notification {
    --info-block-background: rgba(235, 103, 71, 0.1)
  }
}
</style>
