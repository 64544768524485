<template>
  <div class="m-input-autocomplete">
    <div
      class="sf-input-has-clear"
      v-click-outside="closeHandler"
    >
      <div
        :class="className"
        class="sf-input sf-input--filled"
      >
        <input
          v-show="!filled"
          id="streetInput"
          ref="streetInput"
          @input="startAutocompleteStreet"
          :value="street"
          @focus="onFocus"
          @blur="onBlur"
          class="m-input-autocomplete__field"
          :class="{ 'm-input-autocomplete__field--dotted': isRTL }"
          type="text"
          tabindex="1"
          autocomplete="off"
          data-transaction-name="Shipping - Autocomplete House - Field"
        >
        <input
          v-show="filled"
          ref="labelInput"
          class="m-input-autocomplete__field m-input-autocomplete__current-address"
          :value="currentLabel"
          :class="{ 'm-input-autocomplete__field--dotted': isLabelRTL }"
          readonly
          @click="showFields"
        >
        <label for="streetInput" class="sf-input__label">
          {{ label }}
        </label>
        <div
          v-show="selectedBuffer && !filled"
          class="m-input-autocomplete__house">
          <input
            id="houseInput"
            ref="houseInput"
            @input="startAutocompleteHouse"
            :value="house"
            @focus="onFocus"
            @blur="onBlur"
            class="m-input-autocomplete__field"
            type="text"
            tabindex="1"
            autocomplete="off"
            data-transaction-name="Shipping - Autocomplete House - Field"
          >
          <label for="houseInput" class="sf-input__label">
            {{ $t('House') }}
          </label>
        </div>
        <ShippingLoading
          v-show="loadingDelivery"
          :absolute="true"
          background="rgba(255, 255, 255, 0.5)"
        />
        <SfButton
          data-transaction-name="Shipping - Clear Address"
          @click="clearAddress"
          class="sf-button--pure sf-input-has-clear__button"
          type="button"
        >
          <span class="close-icon" />
        </SfButton>
      </div>
      <div
        v-if="isOpenAutocomplete"
        class="m-input-autocomplete__autocomplete"
      >
        <ASmoothReflow>
          <ShippingLoading
            v-show="isLoading"
            class="m-input-autocomplete__loader"
          />
          <p
            class="m-input-autocomplete__autocomplete-no-result"
            v-if="isNoResult && !isLoading"
          >
            {{ message }}
          </p>
          <ul
            class="m-input-autocomplete__autocomplete-result"
            v-else-if="!isLoading"
          >
            <li
              v-for="(item, i) in dataListResult"
              :key="item.id || i"
              class="m-input-autocomplete__autocomplete-item"
              data-transaction-name="Autocomplete - Set Input"
              @click="setInputValue(item)"
            >
              {{ item.label }}
            </li>
          </ul>
        </ASmoothReflow>
      </div>
    </div>
  </div>
</template>
<script>
import ShippingLoading from './shipping-loading.vue';
import { SfButton } from '@storefront-ui/vue';
import { clickOutside } from '@storefront-ui/vue/src/utilities/directives';
import debounce from 'lodash/debounce'
import { isServer } from '@vue-storefront/core/helpers';
import ASmoothReflow from 'theme/components/atoms/a-smooth-reflow.vue';

export default {
  name: 'ShippingAutocomplete',
  components: {
    ASmoothReflow,
    SfButton,
    ShippingLoading
  },
  props: {
    /**
     * selected object
     */
    selected: {
      type: Object,
      default: () => null
    },
    /**
     * objects array
     */
    dataList: {
      type: Array,
      default: () => [],
      required: true
    },
    /**
     * label
     */
    label: {
      type: String,
      default: '',
      required: true
    },
    /**
     * other label
     */
    labelTop: {
      type: String,
      default: '',
      required: false
    },
    loadingDelivery: {
      type: Boolean,
      default: () => false
    },
    loadingDeliveryInput: {
      type: Boolean,
      default: () => false
    },
    errorTrigger: {
      type: Boolean,
      default: () => false
    }
  },
  directives: {
    clickOutside
  },
  data () {
    return {
      isRTL: false,
      isLabelRTL: false,
      isLoading: false,
      isOpenAutocomplete: false,
      isFromList: false,
      street: '',
      house: '',
      selectedBuffer: null,
      focused: false,
      filled: false
    }
  },
  watch: {
    street () {
      if (isServer) return

      this.$nextTick(() => {
        this.isRTL = this.$refs.streetInput?.scrollWidth !== this.$refs.streetInput?.clientWidth
      })
    },
    loadingDeliveryInput (val) {
      if (val) return

      this.isLoading = false
    },
    selected: {
      handler: function (val, oldVal) {
        if (this.isSameAddress(val?.address, oldVal?.address)) return

        if (!val) {
          this.clearFields()
          return
        }

        this.selectedBuffer = val

        const actual = {
          street: this.street,
          house: this.house
        }

        if (this.isSameAddress(val?.address, actual)) return

        if (val?.category === 'adr_address') {
          this.isOpenAutocomplete = false
          this.street = val?.address.street
          this.house = val?.address.house
          this.filled = true

          this.$nextTick(() => {
            this.isLabelRTL = this.$refs.labelInput?.scrollWidth !== this.$refs.labelInput?.clientWidth
          })

          return
        }

        this.street = val?.address.street
        this.house = val?.address.house || ''
        this.isOpenAutocomplete = true
        this.isFromList = val?.from === 'list'

        this.showFields()
      },
      immediate: true
    },
    errorTrigger: {
      handler: function (val, oldVal) {
        if (!val || val === oldVal) return

        this.clearFields()
      }
    }
  },
  computed: {
    message () {
      return this.isFromList && this.street.length
        ? this.$t('Address in on a map')
        : this.$t('Address in text or on a map')
    },
    dataListResult () {
      const isOne = this.dataList.length === 1 && this.house
      const list = isOne ? this.dataList.filter(i => i?.category !== 'adr_street') : this.dataList

      return !this.isLoading
        ? list
        : []
    },
    currentLabel () {
      return this.selectedBuffer?.label ? this.selectedBuffer?.label : ''
    },
    isNoResult () {
      return !this.dataListResult.length;
    },
    className () {
      return {
        'sf-input--has-text': this.street,
        'sf-input--focused': this.focused || this.isOpenAutocomplete
      }
    }
  },
  methods: {
    focusStreetInput () {
      try {
        this.$refs.streetInput.focus()
      } catch (e) {}
    },
    focusHouseInput () {
      try {
        this.$refs.houseInput.focus()
      } catch (e) {}
    },
    toggleAutocomplete () {
      this.isOpenAutocomplete = !this.isOpenAutocomplete

      if (this.isOpenAutocomplete) this.focusStreetInput()
    },
    setInputValue (item) {
      if (item?.category === 'adr_street') {
        this.street = item.address.street
        this.house = ''

        this.$emit('set-value', item)

        return this.focusField()
      }

      this.street = item.address.street
      this.house = item.address.house || ''
      this.filled = true

      this.isOpenAutocomplete = false
      this.$emit('set-value', item)
    },
    startAutocompleteHandler: debounce(function () {
      const data = [this.street, this.house].filter(i => !!i).join(', ')

      this.$emit('input', data)
    }, 500),
    autocomplete () {
      this.isOpenAutocomplete = true
      this.isFromList = true

      return this.startAutocompleteHandler()
    },
    startAutocompleteStreet (e) {
      this.isLoading = true
      this.street = e.target.value
      this.house = ''

      return this.autocomplete()
    },
    startAutocompleteHouse (e) {
      this.isLoading = true
      this.house = e.target.value

      return this.autocomplete()
    },
    onFocus (e) {
      this.focused = true

      const el = e.target;
      const value = el.value.length;

      if ((this.selectedBuffer?.category === 'adr_street' || !this.selected) && this.dataList.length) {
        this.isOpenAutocomplete = true
      }

      el.scrollLeft = el.scrollWidth
      el.setSelectionRange(value, value);
    },
    onBlur () {
      this.focused = false
    },
    closeHandler () {
      if (!this.isOpenAutocomplete) return
      this.isOpenAutocomplete = false
    },
    clearFields () {
      this.selectedBuffer = null
      this.street = ''
      this.house = ''
      this.filled = false
      this.isOpenAutocomplete = false
    },
    clearAddress () {
      this.clearFields()
      this.focusField()
      this.$emit('clear', null)
    },
    focusField () {
      this.$nextTick(() => this.street ? this.focusHouseInput() : this.focusStreetInput())
    },
    isSameAddress (first, second) {
      return first?.street === second?.street &&
        first?.house === second?.house
    },
    showFields () {
      this.filled = false

      this.$nextTick(() => {
        this.isRTL = this.$refs.streetInput?.scrollWidth !== this.$refs.streetInput?.clientWidth

        this.focusField()
      })
    }
  }
}
</script>
<style lang="scss" scoped>
@import "~@storefront-ui/shared/styles/helpers/breakpoints";

.m-input-autocomplete {
  &__street, &__house {
    position: relative;

    label {
      top: -15px;
      left: 0;
    }
  }

  #streetInput {
    flex: 1;
    width: auto;
  }

  &__house {
    width: 97px;
  }

  &__loader {
    padding: var(--spacer-15);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__autocomplete {
    font-size: var(--font-sm);
    font-weight: var(--font-normal);
    line-height: 1.05rem;
    list-style: none;
    padding: 0;
    background: var(--light-gray);
    margin: 2px 0 0 0;
    top: 100%;
    position: absolute;
    width: 100%;
    box-sizing: border-box;
    z-index: var(--autocomplete-index);
    box-shadow: 0 4px 5px rgba(0, 0, 0, 0.06);

    li {
      cursor: pointer;
    }

    &-result {
      padding: 0;
      margin: 0;
      max-height: 10.938rem;
      overflow-y: auto;
      list-style: none;
      box-sizing: border-box;
    }

    &-item {
      padding: 0 var(--spacer-15) var(--spacer-15) var(--spacer-15);
      outline: none;
      -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

      &:first-child {
        padding-top: var(--spacer-15);
      }

      &:hover {
        color: var(--orange);
      }

      &:active, &:focus {
        outline: none;
      }
    }

    ::-webkit-scrollbar {
      width: 3px;
    }

    ::-webkit-scrollbar-track {
      background: transparent;
    }

    ::-webkit-scrollbar-thumb {
      background-color: transparent;
      border-left: 3px solid var(--light-orange);
      background-clip: padding-box;

      &:hover {
        border-left-color: var(--orange);
      }
    }

    &-no-result {
      margin: 0;
      padding: var(--spacer-15);
    }
  }

  ::v-deep {
    .sf-input {
      &__error-message {
        display: none;
      }

      &__label {
        pointer-events: none;
        width: calc(100% - var(--spacer-30));
      }

      &--filled input {
        &::placeholder {
          color: var(--black);
        }
      }

      input {
        --input-padding: 1.5rem var(--spacer-45) .69rem var(--spacer-15);
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
    }
  }

  &__field {
    padding: 0 var(--input-padding) 0 0;
    padding-right: 20px;

    &::placeholder {
      color: transparent;
    }

    &-house {
      padding: 0;
      -webkit-text-fill-color: var(--black);

      @include for-mobile {
        min-width: 25%;
        width: 25%;
      }

      &::placeholder, &::-webkit-input-placeholder {
        color: var(--dark-gray);
        -webkit-text-fill-color: var(--dark-gray);
      }
    }

    &--dotted {
      text-overflow: ellipsis;
      white-space: nowrap;
      direction: rtl;

      &:focus {
        direction: ltr;
      }
    }

    &-field {
      font-size: var(--font-size-15);
      line-height: 1rem;
      font-weight: var(--font-normal);
      white-space: nowrap;

      @include for-mobile{
        &:before {
          position: relative;
          left: -4px;
        }

        &:after {
          content: '';
          display: inline-block;
        }
      }
    }
  }

  .sf-input {
    margin-top: 0;
    display: flex;
    align-items: baseline;
    transition: border 200ms;
    background-color: var(--light-gray);
    border: 1px solid var(--light-gray);
    padding: 1.5rem var(--spacer-15) .69rem var(--spacer-15);

    input {
      height: auto;
      border: none;
    }

    &--error {
      border: 1px solid #F93F3F;
    }
  }

  .sf-input--has-text {
    .sf-input-has-clear__button {
      opacity: 1;
      visibility: visible;
      pointer-events: auto;
    }
  }

  .sf-input--focused {
    border: 1px solid var(--orange)
  }
}
</style>
