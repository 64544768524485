<template>
  <div class="shp-area-delivery">
    <ShippingAutocomplete
        :selected="draft.address"
        :data-list="addressList"
        :label="$t('Shipping address')"
        :loading-delivery="loadingDelivery"
        :loading-delivery-input="loadingDeliveryInput"
        :error-trigger="error.deliveryPointNotFound"
        @input="inputText"
        @set-value="setAddress"
        @clear="clear"
    />
  </div>
</template>

<script>

import ShippingAutocomplete from '$modules/shipping/components/shipping-autocomplete.vue';
import ShippingDeliveryMixin from '$modules/shipping/mixins/shipping-delivery-mixin';

export default {
  name: 'ShippingAreaDelivery',
  components: { ShippingAutocomplete },
  mixins: [ShippingDeliveryMixin]
}
</script>

<style scoped lang="scss">
@import "~@storefront-ui/shared/styles/helpers/breakpoints";
@import "~theme/css/mixins";
@import "~theme/css/px2rem";

.shp-area-delivery {
  padding-top: var(--spacer-xs);
}
</style>
