export default {
  name: 'ShippingDelivery',
  props: {
    draft: {
      type: Object,
      default: () => null
    },
    addressList: {
      type: Array,
      default: () => []
    },
    loadingDelivery: {
      type: Boolean,
      required: true
    },
    loadingDeliveryInput: {
      type: Boolean,
      required: true
    },
    error: {
      type: Object,
      default: () => ({})
    }
  },
  methods: {
    async inputText (text) {
      const { lng, lat } = this.draft.city.coordinates

      this.$emit('autocomplete', { lng, lat, text })
    },
    setAddress (data) {
      this.$emit('set-address', {
        ...data,
        from: 'list'
      })
    },
    clear () {
      this.$emit('set-address', null)
      this.$emit('set-type', null)
    }
  }
}
