<template>
  <div
    class="shipping-loading"
    :class="{
      'shipping-loading--absolute': absolute
    }"
    :style="customStyle"
  >
    <svg class="shipping-loading__loader" width="32" height="8" viewBox="0 0 32 8" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle class="shipping-loading__loader-circle-one" cx="4" cy="4" r="4" fill="#FF6000"/>
      <circle class="shipping-loading__loader-circle-two" cx="16" cy="4" r="4" fill="#FF6000"/>
      <circle class="shipping-loading__loader-circle-three" cx="28" cy="4" r="4" fill="#FF6000"/>
    </svg>
  </div>
</template>

<script>
export default {
  name: 'ShippingLoading',
  props: {
    absolute: {
      type: Boolean,
      default: () => false
    },
    background: {
      type: String,
      default: () => null
    }
  },
  computed: {
    customStyle () {
      if (!this.background) return ''

      return `background: ${this.background}`
    }
  }
}
</script>

<style scoped lang="scss">
.shipping-loading {
  &--absolute {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
  }

  &__loader {
    circle {
      animation-iteration-count: infinite;
      animation-duration: 1200ms;
      animation-name: fadeIn;
    }

    &-circle {
      &-one {
        animation-delay: 200ms;
      }
      &-two {
        animation-delay: 400ms;
      }
      &-three {
        animation-delay: 600ms;
      }
    }
  }

  @keyframes fadeIn {
    0% { opacity: 0.3; }
    50% { opacity: 1; }
    100% { opacity: 0.3; }
  }
}
</style>
